@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Poppins:wght@100;200;300&display=swap);
* {
	font-family: "Inter", sans-serif !important;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-feature-settings: inherit, "tnum";
	        font-feature-settings: inherit, "tnum";
	font-variant-numeric: tabular-nums;
	scroll-behavior: smooth;
}

html {
	height: 100%;
	overflow: auto;
}
body {
	height: 100%;
}

/* For logo loader */
#buble1 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly 3s infinite linear;
	        animation: bubleFly 3s infinite linear;
}

#buble2 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly2 2s infinite linear;
	        animation: bubleFly2 2s infinite linear;
}

#buble3 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly 4s infinite linear;
	        animation: bubleFly 4s infinite linear;
}

#buble4 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly2 3s infinite linear;
	        animation: bubleFly2 3s infinite linear;
}

#buble5 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly 5s infinite linear;
	        animation: bubleFly 5s infinite linear;
}

#buble6 {
	-webkit-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	-webkit-animation: bubleFly2 4s infinite linear;
	        animation: bubleFly2 4s infinite linear;
}

@-webkit-keyframes bubleFly {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		        transform: translate(0) rotate(0deg);
		opacity: 1;
	}
	25% {
		-webkit-transform: translate(0, -10%) rotate(15deg);
		        transform: translate(0, -10%) rotate(15deg);
		opacity: 1;
	}
	50% {
		-webkit-transform: translate(0, -20%) rotate(0deg);
		        transform: translate(0, -20%) rotate(0deg);
		opacity: 1;
	}
	75% {
		-webkit-transform: translate(0, -35%) rotate(-15deg);
		        transform: translate(0, -35%) rotate(-15deg);
		opacity: 0.5;
	}
	80% {
		opacity: 0;
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
	}
	100% {
		-webkit-transform: translate(0, 45%) rotate(-10deg);
		        transform: translate(0, 45%) rotate(-10deg);
		opacity: 0;
	}
}

@keyframes bubleFly {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		        transform: translate(0) rotate(0deg);
		opacity: 1;
	}
	25% {
		-webkit-transform: translate(0, -10%) rotate(15deg);
		        transform: translate(0, -10%) rotate(15deg);
		opacity: 1;
	}
	50% {
		-webkit-transform: translate(0, -20%) rotate(0deg);
		        transform: translate(0, -20%) rotate(0deg);
		opacity: 1;
	}
	75% {
		-webkit-transform: translate(0, -35%) rotate(-15deg);
		        transform: translate(0, -35%) rotate(-15deg);
		opacity: 0.5;
	}
	80% {
		opacity: 0;
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
	}
	100% {
		-webkit-transform: translate(0, 45%) rotate(-10deg);
		        transform: translate(0, 45%) rotate(-10deg);
		opacity: 0;
	}
}

@-webkit-keyframes bubleFly2 {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		        transform: translate(0) rotate(0deg);
		opacity: 1;
	}
	25% {
		-webkit-transform: translate(0, -10%) rotate(30deg);
		        transform: translate(0, -10%) rotate(30deg);
		opacity: 1;
	}
	50% {
		-webkit-transform: translate(0, -20%) rotate(0deg);
		        transform: translate(0, -20%) rotate(0deg);
		opacity: 1;
	}
	75% {
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
		opacity: 0.5;
	}
	80% {
		opacity: 0;
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
	}
	100% {
		-webkit-transform: translate(0, 45%) rotate(-10deg);
		        transform: translate(0, 45%) rotate(-10deg);
		opacity: 0;
	}
}

@keyframes bubleFly2 {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		        transform: translate(0) rotate(0deg);
		opacity: 1;
	}
	25% {
		-webkit-transform: translate(0, -10%) rotate(30deg);
		        transform: translate(0, -10%) rotate(30deg);
		opacity: 1;
	}
	50% {
		-webkit-transform: translate(0, -20%) rotate(0deg);
		        transform: translate(0, -20%) rotate(0deg);
		opacity: 1;
	}
	75% {
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
		opacity: 0.5;
	}
	80% {
		opacity: 0;
		-webkit-transform: translate(0, -35%) rotate(-30deg);
		        transform: translate(0, -35%) rotate(-30deg);
	}
	100% {
		-webkit-transform: translate(0, 45%) rotate(-10deg);
		        transform: translate(0, 45%) rotate(-10deg);
		opacity: 0;
	}
}

#Bottle {
	-webkit-transform-origin: 40% 40%;
	        transform-origin: 40% 40%;
	-webkit-animation: bottle 5s infinite linear;
	        animation: bottle 5s infinite linear;
}

@-webkit-keyframes bottle {
	0%,
	100% {
		-webkit-transform: rotate(5deg);
		        transform: rotate(5deg);
	}

	50% {
		-webkit-transform: rotate(-5deg);
		        transform: rotate(-5deg);
	}
}

@keyframes bottle {
	0%,
	100% {
		-webkit-transform: rotate(5deg);
		        transform: rotate(5deg);
	}

	50% {
		-webkit-transform: rotate(-5deg);
		        transform: rotate(-5deg);
	}
}


.fade-enter {
	opacity: 0;
	display: block;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms;
	display: block;
}

.fade-leave {
	opacity: 0;
	display: none;

}

.fade-leave.fade-leave-active {
	opacity: 0;
	transition: opacity 300ms;
}


