@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&family=Poppins:wght@100;200;300&display=swap");

* {
	font-family: "Inter", sans-serif !important;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-feature-settings: inherit;
	font-variant-numeric: tabular-nums;
	scroll-behavior: smooth;
}

html {
	height: 100%;
	overflow: auto;
}
body {
	height: 100%;
}
